import { render, staticRenderFns } from "./stack-forms.vue?vue&type=template&id=6d04f500&scoped=true"
import script from "./stack-forms.vue?vue&type=script&lang=js"
export * from "./stack-forms.vue?vue&type=script&lang=js"
import style0 from "./stack-forms.vue?vue&type=style&index=0&id=6d04f500&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d04f500",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VChip,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VProgressCircular,VTab,VTabItem,VTabs,VTabsItems})
